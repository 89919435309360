//hamburger menu
function createHamburgerMenu () {

  var $win = $(window);
  var ww = window.innerWidth;
  var currentWidth = window.innerWidth;
  var breakpoint = 1024;

  // ---  横幅が変動した時に実行
//  $win.on('load', function () {
    checkGnavWidth();
//  });

  window.addEventListener("resize", function () {
    // ウインドウ横幅が変わっていない場合
    if ( currentWidth == window.innerWidth ) {
      return false;
    }
    checkGnavWidth();
    currentWidth = window.innerWidth; // 横幅を更新

  });

  // -- toggle action
  $('.toggleWrap').on('click', function () {
    $('>#toggle',this).toggleClass('active');
    $('html').toggleClass('open');

    if ( $('>#toggle',this).hasClass('active') ) {
      $('#gnavi').addClass('active');
    } else {
      $('#gnavi').removeClass('active');
    }

    $('#gnavi').slideToggle( function () { // iOSバグ対策
      if( $(this).hasClass('active') ) {
        $(this).css('display','block');
      } else {
        //監視解除
        $(this).css('display','none');
      }
    });

  });

  // --- dropdown menu
  function drop_down() {
    if ( ww > breakpoint ) {
      $('#gnavi ul li.drop_down').on({
        'mouseenter' : function () {
          $(this).addClass('active');
        },
        'mouseleave' : function () {
          $(this).removeClass('active');
        }
      });
    } else {
      $('#gnavi ul li.drop_down').on('click', function () {
        $('>a', this).toggleClass('active');
        $('>a + .drop_down_menu',this).slideToggle();
      });
    }
  }

  function checkGnavWidth () {
    ww = window.innerWidth;
    globalNavInit();
    drop_down();
  }

  // --- リサイズ時の初期化
  function globalNavInit () {
    if ( ww <= breakpoint ) {
      $('#gnavi').hide();
      $('#gnavi').removeClass('active');
      $('#gnavi > ul > li.drop_down .drop_down_menu').hide();
    } else {
      $('#gnavi').show();
      $('#gnavi > ul > li.drop_down .drop_down_menu').show();
    }
    $('#toggle').removeClass('active');
    $('html').removeClass('open');
    $('#gnavi > ul > li.drop_down > a').removeClass('active');
    $win.off('scroll');
    $('#gnavi ul li.drop_down').off('click');
  }

}
